@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  font-size: 12px;
}
@media (min-width:1536px) {
  html {
    font-size:16px
  }
}

body {
  margin: 0;
  font-family: "Poppins", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
